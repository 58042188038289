<template>
  <div>
    <a-page-header
      style="border: 1px solid rgb(235, 237, 240)"
      :title="$t('recette.fournisseurProfil')"
      class="card"
      @back="() => $router.go(-1)"
    >
      <template slot="subTitle">
        <a-select
          class="mt-0"
          style="width: 300px"
          show-search
          option-filter-prop="children"
          :filter-option="filterOption"
          :placeholder="$t('recette.fournisseur')"
          @change="handleProviderChange"
          :value="provider._id"
        >
          <a-select-option
            :value="item._id"
            v-for="item in providersList"
            :key="item._id"
          >
            {{ item.fullName }}
          </a-select-option>
        </a-select>
      </template>
    </a-page-header>
    <div class="row mb-0">
      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-body">
            <a-form
              :form="form"
              class="card-header p-2"
              @submit="updateProvider"
            >
              <div class="row">
                <div class="col-md-2">
                  <a-form-item :label="$t('inscription.nom') + ':'">
                    <a-input
                      v-if="editingMode"
                      :disabled="loadingSave"
                      :placeholder="$t('inscription.nom')"
                      v-decorator="[
                        'fullName',
                        {
                          rules: [
                            {
                              required: true,
                              message: $t('requis.nom'),
                            },
                          ],
                        },
                      ]"
                    />
                    <a-tag v-else>{{ provider.fullName }}</a-tag>
                  </a-form-item>
                </div>
                <div class="col-md-2">
                  <a-form-item :label="$t('etablissement.adresse') + ':'">
                    <a-input
                      v-if="editingMode"
                      :disabled="loadingSave"
                      :placeholder="$t('etablissement.adresse')"
                      v-decorator="['adress']"
                    />
                    <a-tag v-else>{{ provider.adress }}</a-tag>
                  </a-form-item>
                </div>
                <div class="col-md-2">
                  <a-form-item :label="$t('etablissement.matriculeFiscale')">
                    <a-input
                      v-if="editingMode"
                      :disabled="loadingSave"
                      :placeholder="$t('etablissement.matriculeFiscale')"
                      v-decorator="['matriculeFiscal']"
                    />
                    <a-tag v-else>{{ provider.matriculeFiscal }}</a-tag>
                  </a-form-item>
                </div>
                <div class="col-md-2">
                  <a-form-item :label="$t('etablissement.telephone') + ':'">
                    <a-input
                      v-if="editingMode"
                      :disabled="loadingSave"
                      v-decorator="['phone']"
                      :placeholder="$t('etablissement.telephone')"
                    />
                    <a-tag v-else>{{ provider.phone }}</a-tag>
                  </a-form-item>
                </div>
                <div class="col-md-4">
                  <a-form-item :label="$t('comptabilite.email')">
                    <a-input
                      v-if="editingMode"
                      :disabled="loadingSave"
                      type="email"
                      :placeholder="$t('comptabilite.email')"
                      v-decorator="['email']"
                    />
                    <a-tag v-else>{{ provider.email }}</a-tag>
                  </a-form-item>
                </div>
              </div>
              <div class="row">
                <a-divider />
                <div class="col-md-6">
                  <a-button
                    :type="editingMode ? 'primary' : ''"
                    :loading="loadingSave"
                    :disabled="!editingMode || loadingSave"
                    icon="save"
                    htmlType="submit"
                    class="mr-2"
                  >
                    {{ $t("action.enregistrer") }}
                  </a-button>
                  <a-button
                    v-if="!editingMode"
                    type="primary"
                    icon="edit"
                    @click="setEditingMode"
                  >
                    {{ $t("action.modifier") }}
                  </a-button>
                  <a-button
                    v-else
                    htmlType="submit"
                    icon="close"
                    @click="editingMode = false"
                  >
                    {{ $t("action.annuler") }}
                  </a-button>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="row mt-3 ml-1 mr-1">
              <div class="col-md-8">
                <a-range-picker
                  style="width: 100%"
                  :placeholder="[
                    $t('paiement.dateDebut'),
                    $t('paiement.dateFin'),
                  ]"
                  format="DD-MM-YYYY"
                  :mode="['date', 'date']"
                  :value="monthRangeData"
                  @panelChange="handlePanelChange"
                  @change="handlePanelChange"
                />
              </div>
              <div class="col-md-4">
                <a-button
                  a-button
                  type="primary"
                  style="width: 100%"
                  @click="imprimer"
                >
                  <a-icon type="printer" />{{ $t("action.imprimer") }}
                </a-button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex flex-column justify-content-center">
              <h5 class="mb-3">
                <strong>{{ $t("menu.depenses") }}</strong>
              </h5>
            </div>
            <a-table
              @change="tableChanged"
              rowKey="_id"
              :pagination="true"
              :data-source="data"
              :columns="columns"
              :loading="loadingData"
              :scroll="{ x: 'max-content' }"
            >
              <template slot="date" slot-scope="text, record">
                {{ moment(record.date).format("DD/MM/YYYY") }}
              </template>
              <template slot="label" slot-scope="text, record">
                <div key="label">
                  <a-input
                    v-if="record.editable"
                    style="margin: -5px 0"
                    :value="text"
                    @change="
                      (e) => handleChange(e.target.value, record._id, 'label')
                    "
                  />
                  <template v-else>
                    {{ text }}
                  </template>
                </div>
              </template>
              <template slot="tags" slot-scope="text, record">
                <a-tag color="red" v-if="record.status == 'cancelled'">{{
                  $t("recette.annule")
                }}</a-tag>
              </template>
              <template slot="money" slot-scope="text, record">
                <div key="money">
                  <a-input
                    v-if="record.editable"
                    style="margin: -5px 0"
                    :value="text"
                    @change="
                      (e) => handleChange(e.target.value, record._id, 'money')
                    "
                  />
                  <template v-else>
                    {{ text }}
                  </template>
                </div>
              </template>
              <template slot="depenseType" slot-scope="text, record">
                <template v-if="!record.editable">
                  {{ text }}
                </template>
                <a-select
                  v-else
                  style="width: 100%"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  :default-value="record.depenseType._id"
                  @change="
                    (val) => {
                      record.depenseType._id = val;
                      record.depenseType.name = depense_types.find(
                        (item) => item._id === val
                      ).name;
                      record.depenseType.edited = true;
                    }
                  "
                  :placeholder="$t('recette.typeDepense')"
                  v-decorator="[
                    'depenseType',
                    {
                      rules: [
                        {
                          required: true,
                          message: $t('requis.depense'),
                        },
                      ],
                    },
                  ]"
                >
                  <a-select-option
                    :value="item._id"
                    v-for="item in depense_types"
                    :key="item._id"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </template>
              <template slot="provider" slot-scope="text, record">
                <template v-if="!record.editable">
                  {{ text }}
                </template>
                <a-select
                  v-else
                  style="width: 100%"
                  show-search
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  :default-value="record.provider._id"
                  :placeholder="$t('recette.fournisseur')"
                  @change="
                    (val) => {
                      record.provider._id = val;
                      record.provider.fullName = providersList.find(
                        (item) => item._id === val
                      ).fullName;
                      record.provider.edited = true;
                    }
                  "
                >
                  <a-select-option
                    :value="item._id"
                    v-for="item in providersList"
                    :key="item._id"
                  >
                    {{ item.fullName }}
                  </a-select-option>
                </a-select>
              </template>
              <template slot="expandedRowRender" slot-scope="record">
                <p>
                  {{ $t("recette.doneBy") }} :
                  <a-tag color="blue"> {{ record.userName || "--" }}</a-tag>
                </p>
                <p v-if="record.status == 'cancelled'">
                  {{ $t("recette.cancelledBy") }} :
                  <a-tag color="red">{{ record.cancelledBy }}</a-tag>
                </p>
                <p v-if="record.status == 'cancelled'">
                  {{ $t("recette.dateAnnulation") }} :
                  <a-tag color="red">
                    {{ moment(record.cancelationDate).format("DD/MM/YYYY") }}
                  </a-tag>
                </p>
              </template>
              <div
                slot="filterDropdown"
                slot-scope="{
                  setSelectedKeys,
                  selectedKeys,
                  confirm,
                  clearFilters,
                  column,
                }"
                style="padding: 8px"
              >
                <a-input
                  v-ant-ref="(c) => (searchInput = c)"
                  :placeholder="`${$t('personnel.chercher')} ${column.title}`"
                  :value="selectedKeys[0]"
                  style="width: 188px; margin-bottom: 8px; display: block"
                  @change="
                    (e) =>
                      setSelectedKeys(e.target.value ? [e.target.value] : [])
                  "
                  @pressEnter="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                />
                <a-button
                  type="primary"
                  icon="search"
                  size="small"
                  style="width: 90px; margin-right: 8px"
                  @click="
                    () => handleSearch(selectedKeys, confirm, column.dataIndex)
                  "
                  >{{ $t("paiement.chercher") }}</a-button
                >
                <a-button
                  size="small"
                  style="width: 90px"
                  @click="() => handleReset(clearFilters)"
                  >{{ $t("action.reinitialiser") }}</a-button
                >
              </div>
              <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
              />
              <template
                slot="customRender"
                slot-scope="text, record, index, column"
              >
                <span v-if="searchText && searchedColumn === column.dataIndex">
                  <template
                    v-for="(fragment, i) in text
                      .toString()
                      .split(
                        new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                      )"
                  >
                    <mark
                      v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                      :key="i"
                      class="highlight"
                      >{{ fragment }}</mark
                    >
                    <template v-else>{{ fragment }}</template>
                  </template>
                </span>
                <template v-else>{{ text }}</template>
              </template>
              <template slot="operation" slot-scope="text, record">
                <div
                  class="editable-row-operations"
                  v-if="record.status != 'cancelled'"
                >
                  <span v-if="record.editable">
                    <a-button
                      class="mr-1"
                      size="small"
                      @click="() => save(record._id)"
                      >{{ $t("all.save") }}</a-button
                    >
                    <a-popconfirm
                      :title="$t('all.sureToCancelAlert')"
                      @confirm="() => cancel(record._id)"
                    >
                      <a-button size="small">
                        {{ $t("action.annuler") }}
                      </a-button>
                    </a-popconfirm>
                  </span>
                  <span v-else>
                    <a-button
                      type="primary"
                      :disabled="editingKey !== ''"
                      @click="() => edit(record._id)"
                    >
                      <a-icon
                        type="edit"
                        :disabled="editingKey !== ''"
                        @click="() => edit(record._id)"
                      />{{ $t("action.modifier") }}
                    </a-button>
                  </span>
                </div>
              </template>
              <template slot="delete" slot-scope="text, record">
                <a-popconfirm
                  v-if="record.status != 'cancelled'"
                  :title="$t('all.sureToDelete')"
                  @confirm="() => supp(record._id)"
                >
                  <a-button type="danger">
                    <a-icon type="delete" href="javascript:;" />{{
                      $t("action.supprimer")
                    }}
                  </a-button>
                </a-popconfirm>
              </template>
              <a-button
                slot="print"
                type="primary"
                slot-scope="text, record"
                @click="
                  () => {
                    printOneDepense(record);
                  }
                "
              >
                <a-icon type="file-pdf" />{{ $t("action.imprimer") }}
              </a-button>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { PageHeader, Descriptions } from "ant-design-vue";
import apiClient from "@/services/axios";
import moment from "moment";
import JsPDF from "jspdf";
import "jspdf-autotable";
import { mapState } from "vuex";
import writtenNumber from "written-number";

export default {
  name: "fournisseurProfile",
  components: {
    "a-page-header": PageHeader,
    "a-descriptions": Descriptions,
  },
  async created() {
    apiClient
      .post("/receipt_types/filter", {
        query: { status: "active" },
      })
      .then((res) => (this.receiptTypes = res.data))
      .catch((e) =>
        this.$message.warning(this.$t("warning.connectionProblem"))
      );

    apiClient
      .post("/providers/filter", {
        query: { status: "active" },
      })
      .then((res) => {
        this.providersList = res.data;
        this.provider = res.data.find(
          (e) => e._id == this.$route.params.providerId
        );
        if (!this.provider) {
          this.$router.go(-1);
        } else {
          this.form.setFieldsValue({
            fullName: this.provider.fullName,
            adress: this.provider.adress,
            matriculeFiscal: this.provider.matriculeFiscal,
            phone: this.provider.phone,
            email: this.provider.email,
          });
        }
      })
      .catch((err) => {
        this.$message.error(this.$t("warning.connectionProblem"));
        console.error(err);
      });

    apiClient
      .post("/depense_types/filter", { query: { status: "active" } })
      .then((res) => {
        this.depense_types = res.data;
      })
      .catch();

    apiClient
      .post("/depenses/filter", {
        query: { provider: this.$route.params.providerId },
      })
      .then((res) => {
        res.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        this.data = res.data;
        this.rowData = res.data;
        this.filtredTable = res.data;
        this.cacheData = res.data.map((item) => ({ ...item }));
      })
      .catch()
      .finally(() => {
        this.loadingData = false;
      });
  },
  computed: {
    ...mapState(["settings"]),
  },
  data() {
    return {
      loadingData: false,
      editingKey: "",
      monthRangeData: [],
      editingMode: false,
      provider: {},
      form: this.$form.createForm(this, { name: "profile" }),
      loadingParents: true,
      selectedParent: {},
      modalData: {},
      visableModal: false,
      receiptTypes: [],
      reloadCount: 0,
      loadingClasse: false,
      modeName: "",
      data: [],
      rowData: [],
      filtredTable: [],
      cacheData: [],
      depense_types: [],
      providersList: [],
      tableLoading: false,
      filtredTable: [],
      loadingSave: false,
      columns: [
        {
          title: this.$t("bulletin.label"),
          dataIndex: "label",
          key: "label",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "label",
          },
          onFilter: (value, record) => {
            return record.label.toLowerCase().includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.typeDepense"),
          dataIndex: "depenseType.name",
          key: "depenseType",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "depenseType",
          },
          onFilter: (value, record) => {
            return record.depenseType.name
              .toLowerCase()
              .includes(value.toLowerCase());
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.date"),
          dataIndex: "date",
          key: "date",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "date",
          },
          onFilter: (value, record) =>
            moment(record.date).format("DD/MM/YYYY").includes(value),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("recette.montantTTC"),
          dataIndex: "money",
          key: "money",
          scopedSlots: {
            customRender: "money",
          },
          sorter: (a, b) => a.money - b.money,
        },

        {
          title: "Tags",
          dataIndex: "tags",
          key: "tags",
          scopedSlots: {
            filterDropdown: "filterDropdown",
            filterIcon: "filterIcon",
            customRender: "tags",
          },
          onFilter: (value, record) =>
            (record.status == "cancelled" ? this.$t("recette.annule") : "")
              .toLowerCase()
              .includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                this.searchInput.focus();
              });
            }
          },
        },
        {
          title: this.$t("action.modifier"),
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
          fixed: "right",
        },
        {
          title: this.$t("action.supprimer"),
          dataIndex: "delete",
          scopedSlots: { customRender: "delete" },
          fixed: "right",
        },
        {
          title: this.$t("recette.impression"),
          dataIndex: "print",
          key: "print",
          fixed: "right",
          scopedSlots: {
            customRender: "print",
          },
        },
      ],
    };
  },
  methods: {
    moment,
    handleChange(value, id, column) {
      const newData = [...this.data];
      const target = newData.find((item) => id === item._id);
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    updateProvider(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loadingSave = true;
          apiClient
            .patch("/providers/" + this.provider._id, values)
            .then((res) => {
              this.$message.success(this.$t("success.fournisseurMSA"));
              this.provider.fullName = values.fullName;
              this.provider.adress = values.adress;
              this.provider.matriculeFiscal = values.matriculeFiscal;
              this.provider.phone = values.phone;
              this.provider.email = values.email;
              this.editingMode = false;
            })
            .catch((err) => {
              this.$message.error(this.$t("error.fournisseurNonMSA"));
              console.error(err);
            })
            .finally(() => {
              this.loadingSave = false;
            });
        }
      });
    },

    setEditingMode() {
      this.editingMode = true;
      setTimeout(() => {
        this.form.setFieldsValue({
          fullName: this.provider.fullName,
          adress: this.provider.adress,
          matriculeFiscal: this.provider.matriculeFiscal,
          phone: this.provider.phone,
          email: this.provider.email,
        });
      }, 50);
    },
    cancelModel() {
      this.visableModal = false;
      this.reloadCount++;
    },
    reloadComp() {
      this.$emit("reload");
    },
    tableChanged(pagination, filters, sorter, extra) {
      this.filtredTable = extra.currentDataSource;
    },
    imprimer() {
      this.$gtag.event("Imp Dépenses", {
        event_category: "Impression PDF",
        event_label: "comptabilite:Dépenses",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      const pdf_wdith = doc.internal.pageSize.getWidth();
      doc.setFont("Amiri");
      var date = new Date();
      let filterText = "";
      if (this.monthRangeData[0])
        filterText =
          "De " +
          this.monthRangeData[0]._d.toLocaleDateString("fr-FR", {
            month: "long",
            year: "numeric",
            day: "numeric",
          }) +
          " à " +
          this.monthRangeData[1]._d.toLocaleDateString("fr-FR", {
            month: "long",
            year: "numeric",
            day: "numeric",
          });
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };

      doc.text(60, 70, filterText);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      const array2 = [];
      let sommeHT = 0;
      let sommeTTC = 0;
      let sommeTTC2 = 0;
      for (let i = 0; i < this.filtredTable.length; i++) {
        arr.push(this.filtredTable[i].userName || "--");
        arr.push(this.filtredTable[i].label || "");
        arr.push(this.filtredTable[i].depenseType.name);
        arr.push(
          this.filtredTable[i].provider
            ? this.filtredTable[i].provider.fullName
            : "--"
        );
        arr.push(
          this.filtredTable[i].provider
            ? this.filtredTable[i].provider.phone
            : "--"
        );
        arr.push(moment(this.filtredTable[i].date).format("DD/MM/YYYY"));
        arr.push(this.filtredTable[i].money);
        if (this.filtredTable[i].status == "cancelled") {
          sommeTTC2 += parseInt(this.filtredTable[i].money);
          arr.push(
            moment(this.filtredTable[i].cancellationDate).format("DD/MM/YYYY")
          );
          arr.push(this.filtredTable[i].cancelledBy);
          array2.push(arr);
        } else {
          sommeTTC += parseInt(this.filtredTable[i].money);

          array.push(arr);
        }

        arr = [];
      }

      doc.setFontSize(15);
      doc.text(pdf_wdith / 2, 60, "Dépenses : " + this.provider.fullName, {
        align: "center",
      });

      doc.autoTable({
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [
          [
            "Effectué par",
            "Libellé",
            "Type",
            "Fournisseur",
            "Téléphone",
            "Date",
            "Montant TTC",
          ],
        ],
        body: array,
        foot: [
          [
            "Total",
            {
              colSpan: 3,
              content: writtenNumber(sommeTTC, { lang: "fr" }) + " DT",
              styles: { halign: "center" },
            },
            sommeTTC,
          ],
        ],
      });

      doc.setFontSize(15);
      doc.text(
        pdf_wdith / 2,
        doc.autoTableEndPosY() + 15,
        "Dépenses annulé : " + this.provider.fullName,
        {
          align: "center",
        }
      );

      doc.autoTable({
        startY: doc.autoTableEndPosY() + 30,
        head: [
          [
            "Effectué par",
            "Libellé",
            "Type",
            "Fournisseur",
            "Téléphone",
            "Date",
            "Montant TTC",
            "Date d'annulation",
            "Annulé par",
          ],
        ],
        styles: {
          fontSize: 9,
        },
        headStyles: {
          fontSize: 9,
        },
        body: array2,
        foot: [
          [
            "Total",
            {
              colSpan: 3,
              content: writtenNumber(sommeTTC2, { lang: "fr" }) + " DT",
              styles: { halign: "center" },
            },
            sommeTTC2,
          ],
        ],
      });
      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save("Dépenses : " + this.provider.fullName + ".pdf");
    },
    edit(id) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item._id)[0];
      this.editingKey = id;
      if (target) {
        target.editable = true;
        this.data = newData;
      }
    },
    supp(key) {
      apiClient
        .patch("/depenses/" + key, {
          data: {
            status: "cancelled",
          },
        })
        .then(() => {
          this.$message.success(this.$t("success.depenseSupp"));
          const dataSource = [...this.data];
          this.data = dataSource.filter((item) => item._id !== key);
          this.filtredTable = this.filtredTable.filter(
            (item) => item._id !== key
          );
        })
        .catch(() => this.$message.error(this.$t("error.depenseNonSupp")));
    },
    save(id) {
      const newData = [...this.data];
      const newCacheData = [...this.cacheData];
      const target = newData.find((item) => id === item._id);
      const targetCache = newCacheData.find((item) => id === item._id);
      const toUpdate = {
        money: target.money,
        label: target.label,
      };
      if (target.depenseType.edited) {
        toUpdate.depenseType = target.depenseType._id;
        target.depenseType.edited = false;
      }
      if (target.provider.edited) {
        toUpdate.provider = target.provider._id;
        target.provider.edited = false;
      }
      apiClient
        .patch(`/depenses/${id}`, {
          data: toUpdate,
        })
        .then((res) => {
          this.$message.success(this.$t("success.depenseMSA"));
          if (target && targetCache) {
            delete target.editable;
            this.data = newData;
            Object.assign(targetCache, target);
            this.cacheData = newCacheData;
          }
        })
        .catch((err) => this.$message.error(this.$t("error.depenseNonMSA")));

      this.editingKey = "";
    },
    cancel(id) {
      const newData = [...this.data];
      const target = newData.filter((item) => id === item._id)[0];
      this.editingKey = "";
      if (target) {
        Object.assign(
          target,
          this.cacheData.filter((item) => id === item._id)[0]
        );
        delete target.editable;
        this.data = newData;
      }
    },
    printOneDepense(record) {
      this.$gtag.event("Imp one depense", {
        event_category: "Impression PDF",
        event_label: "comptabilite:Dépenses",
        value: 1,
      });

      let arrayOfImages = this.settings.image;
      for (var i = 0; i < arrayOfImages.length; i++) {
        if (this.settings.activeBuilding === arrayOfImages[i].db) {
          var imgData = new Image();
          imgData.src = arrayOfImages[i].logo;
        }
      }
      const doc = new JsPDF();
      doc.setFont("Amiri");
      var date = new Date();

      var titre = "Dépense :  " + record.depenseType.name;

      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      doc.setFontSize(15);
      doc.text(80, 60, titre);
      // doc.text(20, 70, "Nom et Prénom :" + record.nomClient);
      doc.setFontSize(10);
      doc.addImage(imgData, "JPEG", 8, 8, 40, 40);
      doc.setFontSize(12);
      doc.text(150, 40, date.toLocaleDateString("fr-FR", options));
      var arr = [];
      const array = [];
      arr.push(record.label || "");
      arr.push(record.depenseType.name);
      arr.push(moment(record.date).format("DD/MM/YYYY"));
      arr.push(record.provider ? record.provider.fullName : "--");
      arr.push(parseFloat(record.money).toFixed(2));
      array.push(arr);

      arr = [];

      arr.push({
        colSpan: 4,
        content: "Total",
        styles: { valign: "middle", halign: "center", fontStyle: "bold" },
      });
      arr.push({
        content: parseFloat(record.money).toFixed(2),
        styles: { valign: "middle", halign: "center", fontStyle: "bold" },
      });
      array.push(arr);

      arr = [];

      doc.autoTable({
        styles: {
          lineWidth: 0.2,
          valign: "middle",
          halign: "center",
          fontStyle: "bold",
          font: "Amiri",
        },
        startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 80,
        head: [["Libellé", "Type", "Date", "Fournisseur", "Montant TTC"]],
        body: array,
        foot: [
          [
            {
              colSpan: 5,
              content:
                "Le montant total est " +
                writtenNumber(record.money, { lang: "fr" }) +
                " DT",
              styles: { halign: "center" },
            },
          ],
        ],
      });

      //page numbering
      const pages = doc.internal.getNumberOfPages();
      const pageWidth = doc.internal.pageSize.width;
      const pageHeight = doc.internal.pageSize.height;
      doc.setFontSize(8); //Optional

      for (let j = 1; j < pages + 1; j++) {
        let horizontalPos = pageWidth / 2;
        let verticalPos = pageHeight - 10;
        doc.setPage(j);
        doc.text(`Page ${j} / ${pages}`, horizontalPos, verticalPos, {
          align: "center",
        });
      }
      //end page numbering
      doc.save(titre + ".pdf");
    },

    selectreceiptType(val) {
      let receiptTypeName = this.receiptTypes.find((e) => e._id == val).name;
      console.log(receiptTypeName);
      this.modeName = receiptTypeName;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    handleOk(e) {
      this.chequeVisible = false;
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm();
      this.searchText = selectedKeys[0];
      this.searchedColumn = dataIndex;
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    handlePanelChange(value) {
      this.monthRangeData = value;
      if (value)
        this.data = this.rowData.filter((elem) => {
          const start = moment(value[0]).set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          });
          const end = moment(value[1]).set({
            hour: 24,
            minute: 59,
            second: 59,
            millisecond: 99,
          });
          return moment(elem.date).isBetween(start, end, undefined, "[]");
        });
    },
    handleProviderChange(val) {
      this.provider = this.providersList.find((e) => e._id == val);

      this.$router.push({
        path: "/fournisseur/" + val,
      });

      this.loadingData = true;
      apiClient
        .post("/depenses/filter", {
          query: { provider: val },
        })
        .then((res) => {
          res.data.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          this.data = res.data;
          this.rowData = res.data;
          this.filtredTable = res.data;
          this.cacheData = res.data.map((item) => ({ ...item }));
        })
        .catch()
        .finally(() => {
          this.loadingData = false;
        });

      // load providers data again

      apiClient
        .post("/providers/filter", {
          query: { status: "active" },
        })
        .then((res) => {
          this.providersList = res.data;
        })
        .catch((err) => {
          this.$message.error(this.$t("warning.connectionProblem"));
          console.error(err);
        });
    },
  },
};
</script>
